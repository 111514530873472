<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12"> 
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MyLinks"/> 
                        <mbs-item-manager
                            :add_vertical_lines="true"
                            :row_click="true"
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :field_items="DATA.FIELDS.nfc_cards"
                            :header_items="HEADER_ITEMS" 
                            :data_items="SwipingCards"    
                            :item_data_include="{
                                card_type:DATA.ITEMS.NFC_CARDS.value
                            }"    
                            :is_joined="true" 
                            :select_items="Selectors"   
                            :path_item="PATH_ITEM"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.NFC_CARDS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.NFC_CARDS.names,
                NAME_ITEM:DATA.ITEMS.NFC_CARDS.name,
                VALUE_ITEMS:DATA.ITEMS.NFC_CARDS.values,
                VALUE_ITEM:DATA.ITEMS.NFC_CARDS.value,       
                table_actions:[
                    {icon:"add",type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add Swiping Card"},  
                ],
                DATA:DATA
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
                this.MBS.events.$on('ON_NFC_MESSAGE', this.ON_NFC_MESSAGE);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try { 
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: { 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Cards: state=> state.items[(DATA.ITEMS.CARDS.values).toUpperCase()],
                JoinedCards: state=> state.join[(DATA.ITEMS.CARDS.values).toUpperCase()],
                PrinterTypes: state=> state.app[(DATA.ITEMS.PRINTER_TYPES.values).toUpperCase()]
            }),  
            DataItems(){
                return this.JoinedCards?this.JoinedCards:this.Cards
            },
            SwipingCards(){
                let cards = this.DataItems  
                if(!cards){return null}
                let items = cards.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.card_type)
                     == this.MBS.actions.TEXT_UP(DATA.ITEMS.NFC_CARDS.value)
                }) 
                return items
            },
            Selectors(){ 
                let PrinterTypes = this.PrinterTypes 
                return{
                    PrinterTypes: PrinterTypes, 
                }
            },
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            },  
            MyLinks(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("cards",2,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.cards,true) 
                return path
            }, 
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Exp. Date",value:'card_expire_date',align:"center",show:true},     
                    {id:0,name:"Card No.",value:'code',align:"center",show:true},     
                    {id:0,name:"Card UID",value:'card_uid',align:"center",show:true},     
                    {id:0,name:"Card Type",value:"card_type",show:true},  
                    {id:0,name:"Created By",value:"user_name",show:true},  
                    {id:0,name:"Amount (MWK)",value:"loaded_amount_",align:"right",show:true},  
                    {id:0,name:"Description",value:"description",show:true},  
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"edit"}, 
                        {name:"Archive "+this.NAME_ITEM,action:"archive_item",icon:"mdi-delete"}, 
                    ]}, 
                ]
            }, 
        },
        methods: {  
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null 
                    if (action == "order_stock") {
                        this.MBS.actions.go(fullPath+"/order")
                    }else if (action == "received_stock") {
                        this.MBS.actions.go(fullPath+"/receive")
                    }else if (action == "return_stock") {
                        this.MBS.actions.go(fullPath+"/return")
                    }else if (action == "transfer_stock") {
                        this.MBS.actions.go(fullPath+"/transfer")
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            ON_NFC_MESSAGE(event){
                try {
                    let code = event?.data
                    console.log(event,'event-----------++');
                    console.log(code,'code-----------++');

                    if (code) {  
                        let size = this.MBS.actions.SIZE(code)
                        if (size>3 && code!="Error") { 
                            this.MBS.events.$emit("ITEM_ACTION",{
                                NAME_ITEM:this.NAME_ITEM,
                                action:"add_item",
                                input:{card_uid:code}
                            }) 
                        } else {  
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:"ERROR",
                                text:"Please, Scan again.",
                                btnYes:"OK"
                            })
                        }   
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_NFC_MESSAGE',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            FEEDBACKS(time){  
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
            this.MBS.events.$off('ON_NFC_MESSAGE', this.ON_NFC_MESSAGE);    
        },
        watch: {  
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
